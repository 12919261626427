body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.site-layout-background {
  background: #fff;

  border-width: 0px 0px 0px 1px;
  border-style: solid;
  border-color: lighten(#7f8c8d, 20%);
}
.container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;

  .ant-layout-header {
    margin: 0;
    height: 60px;
    min-height: 60px;
    color: white;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    // background: #0068eb;
    background: white;
    color: black !important;
    border-bottom: 1px solid rgb(181, 188, 189);

    .logo {
      padding: 0 9px 0 10px;
      position: relative;

      img {
        position: relative;
        top: -2px;
      }
    }

    .logo:first-child {
      padding-left: 0;
    }

    .logo + .logo::before {
      content: "";
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      height: 15px;
      width: 1px;
      background-color: #ccc;
    }

    .logo-title {
      display: flex;
      height: 70%;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      padding: 6px 8px;
      // line-height: 1.2em;
      border-radius: 8px;
      transition: background-color 80ms linear;

      h1 {
        margin: 0;
        flex-shrink: 0;
        flex-grow: 1;
        font-size: 19px;
        font-weight: 400;
      }

      &:hover {
        background-color: #f5f5f5;
      }
    }

    .anticon-translation {
      position: absolute;
      right: 24px;
      top: 20px;
      z-index: 10;
      font-size: 19px;
    }

    button {
      color: black !important;
    }
  }

  .ant-layout-footer {
    color: #222;
    padding: 13px 24px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    background-color: #2980b922;

    border-style: solid;
    border-width: 1px 0 0 1px;
    border-color: lighten(#7f8c8d, 20%);

    a {
      color: #2c3e50;
      font-weight: bolder;
    }
  }
  .ant-layout-sider-trigger,
  .ant-layout-sider-has-trigger,
  .ant-layout-sider {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-card-head {
    border-bottom: none;
  }
  .ant-card-head-wrapper {
    border-bottom: 1px solid #f0f0f0;
  }
}

@primary-color: #1890ff;